import { IAboutCard } from "@interface/propsInterface";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import { convertHexToRGBA } from "@utils/convertHexToRGBA";
import {
  color,
  fontSize,
  fontWeight,
  screenBreakPoint,
} from "@styles/styleVariables";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import { pushItemLeft, pushItemUp } from "@styles/animations";

export const AboutCard = ({ aboutCard }: IAboutCard) => {
  return (
    <SAboutCard to={aboutCard?.main_link?.url ?? ""} variants={pushItemUp}>
      <div className="about-card-content">
        <h1 className="about-card-number">
          0{Number(aboutCard?.rank ?? "0") + 1}
        </h1>
        <p className="about-card-title">{aboutCard?.title ?? ""}</p>
      </div>
      <div className="about-card-image-wrapper">
        <GatsbyImage
          image={
            aboutCard?.coverImage?.localFile?.childImageSharp
              ?.gatsbyImageData ?? ""
          }
          alt={aboutCard?.title ?? ""}
          className="about-card-image"
        ></GatsbyImage>
        <div className="about-card-image-overlay"></div>
      </div>
    </SAboutCard>
  );
};

const SAboutCard = styled(motion(Link))`
  margin: 1rem;
  position: relative;
  width: 33%;
  height: 60rem;

  @media (max-width: ${screenBreakPoint.sm}) {
    width: 100%;
    height: 20rem;
  }

  &:hover {
    .about-card-image-wrapper {
      .about-card-image-overlay {
        transition: all 0.2s ease-in-out;
        background: ${convertHexToRGBA(color.prussianBlue, 0.33)};
      }
    }

    .about-card-content {
      .about-card-title {
        transition: all 0.2s ease-in-out;
        color: ${color.sunglow};
      }
    }
  }

  .about-card-image-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;

    .about-card-image {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transform: scaleX(-1);
    }

    .about-card-image-overlay {
      background: ${convertHexToRGBA(color.black, 0.5)};
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .about-card-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;

    .about-card-title {
      text-align: right;
      font-weight: ${fontWeight.medium};
      font-size: ${fontSize.md};
      color: ${color.culture};
    }

    .about-card-number {
      font-weight: ${fontWeight.extraBold};
      font-size: ${fontSize.xxl};
      color: ${color.sunglow};
    }
  }
`;
