import * as React from "react"
import { Layout } from "@components/layout";
import { Hero } from "@components/index/hero";
import { About } from "@components/index/about";
import { AboutCarousel } from "@components/index/aboutCarousel";
import { Testimonial } from "@components/index/testimonial";
import { HeadMetadata } from "@components/headMetadata";
import { AboutVideo } from "@components/index/aboutVideo";

const IndexPage = () => (
  <Layout>
    <HeadMetadata location="Home"></HeadMetadata>
    <Hero></Hero>
    <div className="content-wrapper">
      <About></About>
    </div>
    <AboutVideo></AboutVideo>
    <div className="content-wrapper">
      <Testimonial></Testimonial>
    </div>
  </Layout>
);
 
export default IndexPage;