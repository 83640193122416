import React from "react";
import {
  color,
  font,
  fontSize,
  fontWeight,
  screenBreakPoint,
} from "@styles/styleVariables";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { convertHexToRGBA } from "@utils/convertHexToRGBA";
import { BrandButton } from "@components/reusables/brandButton";
import { ButtonEnum } from "src/enums/buttonsEnum";
import { IQCarousel } from "@interface/queryInterface";
import Carousel from "nuka-carousel";
import { motion } from "framer-motion";
import { parentAnimation, pushItemUp } from "@styles/animations";

export const Hero = () => {
  const query = useStaticQuery(graphql`
    query heroQuery {
      heroContent: strapiHeroContent {
        ctaName
        ctaUrl
        description
        slogan
        id
        heroImage {
          localFile {
            publicURL
            name
          }
        }
      }
      heroCarouselQuery: strapiCarousel(name: { eq: "hero-carousel" }) {
        name
        images {
          localFile {
            name
            childImageSharp {
              gatsbyImageData(quality: 100, placeholder: BLURRED)
              id
            }
          }
        }
      }
    }
  `);

  const { ctaName, ctaUrl, description, slogan, heroImage, id } =
    query.heroContent;

  const carousel = query.heroCarouselQuery;

  return (
    <SHero initial="before" animate="after" variants={parentAnimation(0)}>
      <motion.div
        initial="before"
        animate="after"
        variants={pushItemUp}
        className="hero-content-wrapper content-wrapper"
      >
        <motion.h1 variants={pushItemUp}>{slogan ?? ""}</motion.h1>
        <p>{description ?? ""}</p>
        <BrandButton
          link={ctaUrl ?? ""}
          name={ctaName ?? ""}
          category={ButtonEnum.Primary}
          id={id ?? ""}
        ></BrandButton>
      </motion.div>
      <div className="hero-image-wrapper">
        <Carousel
          autoplay={true}
          autoplayInterval={3000}
          pauseOnHover={true}
          wrapAround={true}
          withoutControls={true}
          cellSpacing={0}
          className="hero-carousel-wrapper"
        >
          {carousel.images.map((p: IQCarousel) => (
            <GatsbyImage
              image={p?.localFile?.childImageSharp?.gatsbyImageData ?? ""}
              alt={p?.localFile?.name ?? ""}
              className="hero-carousel-image"
            ></GatsbyImage>
          ))}
        </Carousel>
        <div className="hero-image-overlay"></div>
      </div>
    </SHero>
  );
};

const SHero = styled(motion.div)`
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  padding: -5rem;

  .hero-image-wrapper {
    position: absolute;
    background: ${color.black};
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;

    .hero-carousel-wrapper {
      width: 100%;
      .hero-carousel-image {
        object-fit: cover;
        width: 100%;
        min-height: 100vh;
      }
    }

    .hero-image-overlay {
      background: ${convertHexToRGBA(color.black, 0.66)};
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  .hero-content-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 2;
    width: 50%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    @media (max-width: ${screenBreakPoint.md}) {
      width: 75%;
    }

    @media (max-width: ${screenBreakPoint.xs}) {
      width: 100%;
    }

    h1 {
      color: ${color.sunglow};
      font-size: ${fontSize.lg};
      font-weight: ${fontWeight.semiBold};
    }

    p {
      margin: 2rem 0rem;
      color: ${color.culture};
      font-weight: ${fontWeight.light};
    }
  }
`;
