import React from "react";
import styled from "styled-components";
import { useStaticQuery, graphql } from "gatsby";
import { BrandButton } from "@components/reusables/brandButton";
import { ButtonEnum } from "src/enums/buttonsEnum";
import { color, fontWeight, screenBreakPoint } from "@styles/styleVariables";
import { AboutCard } from "./aboutCard";
import { IQAboutCard } from "@interface/queryInterface";
import { motion } from "framer-motion";
import { fadeIn, parentAnimation, pushItemDown, pushItemLeft } from "@styles/animations";

export const About = () => {
  const query = useStaticQuery(graphql`
    query aboutQuery {
      aboutContent: strapiAboutContent {
        slogan
        ctaUrl
        ctaName
        aboutUs
      }
      aboutCards: allStrapiAboutCard(sort: { fields: rank, order: ASC }) {
        nodes {
          strapi_id
          title
          rank
          main_link {
            url
            strapi_id
          }
          coverImage {
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `);

  const { aboutUs, slogan, id, ctaName, ctaUrl } = query.aboutContent;
  const aboutCards = query.aboutCards.nodes;
  return (
    <SAbout initial="before" whileInView="after" variants={parentAnimation(0.25)}>
      <motion.div className="about-content" variants={pushItemLeft}>
        <h1>ABOUT US</h1>
        <p className="about-slogan">{slogan ?? ""}</p>
        <p className="about-writeup">{aboutUs ?? ""}</p>
        <BrandButton
          name={ctaName ?? ""}
          link={ctaUrl ?? ""}
          id={id ?? ""}
          category={ButtonEnum.Tertiary}
        ></BrandButton>
      </motion.div>
      <div className="about-cards">
        {aboutCards.map((c: IQAboutCard) => (
          <AboutCard aboutCard={c}></AboutCard>
        ))}
      </div>
    </SAbout>
  );
};

const SAbout = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      color: ${color.oxfordBlue};
      margin-bottom: 2rem;
    }

    .about-writeup {
      font-weight: ${fontWeight.light};
      margin-bottom: 2rem;
      text-align: center;
    }

    .about-slogan {
      font-weight: ${fontWeight.semiBold};
      margin-bottom: 1rem;
    }
  }

  .about-cards {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%;
    padding: 2rem 0rem;

    @media (max-width: ${screenBreakPoint.sm}) {
      flex-direction: column;
    }
  }
`;
