import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import Carousel from "nuka-carousel/lib/carousel";
import { color, fontWeight } from "@styles/styleVariables";
import { convertHexToRGBA } from "@utils/convertHexToRGBA";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCaretLeft, faSquareCaretRight } from "@fortawesome/free-solid-svg-icons";
import { IQTestimonial } from "@interface/queryInterface";

export const Testimonial = () => {
  const query = useStaticQuery(graphql`
    query testimonialQuery {
      testimonialQuery: allStrapiTestimonial(sort: {fields: rank, order: ASC}) {
        nodes {
          id
          name
          testimony
          strapi_id
        }
      }
    }
  `)

  const testimonials = query.testimonialQuery.nodes;

  return (
    <STestimonial className="content-wrapper">
      <h1>A LOT OF HAPPY STUDENTS AND PARENTS!</h1>
      <div className="carousel-wrapper">
        <Carousel 
          className="testimony-carousel"
          wrapAround={true}
          renderCenterLeftControls={({ previousSlide }) => (
            <FontAwesomeIcon 
              icon={faSquareCaretLeft} 
              size="4x" 
              onClick={previousSlide}
              className="testimonial-control"
            ></FontAwesomeIcon>
          )}
          renderCenterRightControls={({ nextSlide }) => (
            <FontAwesomeIcon 
              icon={faSquareCaretRight} 
              size="4x" 
              onClick={nextSlide}
              className="testimonial-control"
            ></FontAwesomeIcon>
        )}>
          {testimonials.map((t:IQTestimonial) => 
            <div className="testimonial">
              <q>{t?.testimony ?? ""}</q>
              <i>- {t?.name ?? ""}</i>
            </div>
          )}
        </Carousel>
      </div>
    </STestimonial>
  )
}

const STestimonial = styled.div`
  min-height: 50vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
  h1 {
    color: ${color.oxfordBlue};
    margin-bottom: 2rem;
    text-align: center;
  }
  .carousel-wrapper {
    width: 100%;
    overflow: auto;
    .testimony-carousel {
      width: 10rem;
      height: 100%;
      .testimonial {
        height: 100%;
        width: 90%;
        margin: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        q, i {
          display: inline;
          text-align: center;
          color: ${convertHexToRGBA(color.oxfordBlue, 0.75)};
          font-weight: ${fontWeight.light};
        }

        i {
          margin-top: 2rem;
          font-weight: ${fontWeight.regular};
        }
      }
    }
  }
  .slider-container {
    .slider-control-centerleft, .slider-control-centerright {
      opacity: 50%;
      cursor: pointer;
      &:hover {
        opacity: 100%;
      }
    }

    .slider-control-bottomcenter {
      display: none;
    }
  }
` 