import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { color } from "@styles/styleVariables";
import { IQCarousel } from "@interface/queryInterface";

export const AboutVideo = () => {

  const query = useStaticQuery(graphql`
    query videoQuery {
      video: strapiHeroContent {
        heroImage {
          localFile {
            publicURL
            name
          }
        }
      }
    }
  `);

  const video = query.video.heroImage;
  return (
    <SAboutVideo>
      <video 
        src={ video?.localFile?.publicURL ?? "" } 
        className="hero-image" 
        autoPlay 
        loop 
        playsInline 
        muted
      ></video> 
    </SAboutVideo>
  )
}

const SAboutVideo = styled.div`
  height: 50vh;
  width: 100%;
  video {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;